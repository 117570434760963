<template>
  <div class="fill-height">
    <TopNav />
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-3 col-12">
          <img
            :src="teacher.profile_image"
            width="200"
            height="200"
            alt="user_profile"
            class="clip-circle"
          />
        </div>
        <div class="col-md-9 col-12">
          <h2>{{ teacher.first_name }} {{ teacher.last_name }}</h2>
          <b>ประวัติโดยย่อ</b>
          <div
            style="
              background-color: #fafafa;
              border-radius: 25px;
              min-height: 100px;
            "
            class="p-3"
            v-html="teacher.short_description"
          ></div>
          <b>ประวัติ</b>
          <div
            style="
              background-color: #fafafa;
              border-radius: 25px;
              min-height: 200px;
            "
            class="p-3"
            v-html="teacher.description"
          ></div>
        </div>
      </div>
    </div>
    <div class="horizon-line my-3"></div>
    <div class="container">
      <b-row>
        <b-col
          v-for="item in teacher.subject"
          :key="item.id"
          md="4"
          cols="12"
          class="my-3"
        >
          <CourseCard
            :id="item.id"
            :header="item.name"
            :content="item.description"
            :image="item.banner_image ? item.banner_image : noImage"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TopNav from "@/components/topNav.vue";
import CourseCard from "@/components/CourseCard.vue";
import { mapState } from "vuex";
import noImage from "../assets/image/mock/no-image.png";
export default {
  components: {
    TopNav,
    CourseCard,
  },
  data() {
    return { noImage };
  },
  computed: {
    ...mapState({
      teacher: (state) => {
        return state.Teachers.teacher;
      },
    }),
  },
  async created() {
    await this.getContent();
  },
  methods: {
    async getContent() {
      const id = this.$route.query.id;
      await this.$store.dispatch("Teachers/getTeacher", id);
    },
  },
};
</script>

<style>
.clip-circle {
  clip-path: circle(100px at center);
  /* OLD VALUE example: circle(245px, 140px, 50px); */
  /* Yep, even the new clip-path has deprecated stuff. */
}
</style>
