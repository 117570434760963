<template>
  <div>
    <div class="mb-2 py-2 px-2 card-plain">
      <div class="row" style="height: 100%">
        <div
          class="col-6 col-md-6 float-left"
          style="cursor: pointer"
          @click="teacherDetail(dataTeacher.id)"
        >
          <b-img
            class="rounded-circle"
            v-if="dataTeacher && dataTeacher.profile_image"
            :src="dataTeacher.profile_image"
            height="80px"
            width="80px"
          ></b-img>
          <b-img
            class="rounded-circle"
            v-else
            :src="noImage"
            height="80px"
            width="80px"
          ></b-img>
          <b-card-text>
            {{
              dataTeacher
                ? dataTeacher.first_name + " " + dataTeacher.last_name
                : ""
            }}
          </b-card-text>
        </div>
        <div class="col-6 col-md-6 text-left">
          <div>
            <span class="orange-text mr-2"> <i class="bi bi-star"></i></span>
            {{ lessonsAmount }}
            บทเรียน
          </div>
          <div>
            <span class="orange-text mr-2">
              <i class="bi bi-chat-text"></i
            ></span>
            {{ quizAmount }} แบบทดสอบ
          </div>
          <div>
            <img class="mr-1" :src="playCircle" alt="" />
            {{ totalTime }} นาที
          </div>

          <div v-if="courseStatus">
            <button
              v-if="!courseStatus.isBan"
              class="btn btn-block btn-secondary mt-3"
            >
              {{ courseStatus.user_enroll_status.name }}
            </button>
            <button v-else disabled class="btn btn-block btn-secondary mt-3">
              ระงับการใช้งาน
            </button>
          </div>
          <div v-else>
            <button
              :disabled="courseTimeCalculateDisabled(course.enroll)"
              class="btn btn-block main-orange-btn-outline mt-3"
              @click="register()"
            >
              {{
                courseTimeCalculateDisabled(course.enroll)
                  ? "ปิดรับสมัคร"
                  : "สมัครเรียน"
              }}
            </button>
          </div>

          <!-- <button
            v-else-if="
              course.register_start_at
                ? !inTimeDuration(
                    course.register_start_at,
                    course.register_end_at
                  )
                : false
            "
            disabled
            class="btn btn-block main-orange-btn-outline mt-3"
          >
            หมดเวลาสมัคร
          </button>

          <button
            v-else-if="courseStatus"
            class="btn btn-block btn-secondary mt-3"
          >
            {{ courseStatus.user_enroll_status.name }}
          </button>

          <button
            v-else
            @click="register()"
            class="btn btn-block main-orange-btn-outline mt-3"
          >
            สมัครเรียน
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import noImage from "../assets/image/mock/no-image.png";
import img from "./../../public/temp.jpg";
import playCircle from "../assets/image/icon/PlayCircle.png";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "teacher",
  computed: {
    ...mapState({
      userData: (state) => state.Auth.userData,
      access_token: (state) => state.Auth.access_token,
      course: (state) => {
        return state.Courses.course;
      },
    }),
  },
  data() {
    return {
      playCircle: playCircle,
      // courseStatus: false,
      lessonsAmount: 0,
      quizAmount: 0,
      totalTime: 0,
      noImage: noImage,
      img: img,
    };
  },
  created() {
    this.countLesson();
    // this.checkCourseStatus();
  },
  props: {
    lessons: null,
    dataTeacher: null,
    courseStatus: null,
    // course: null,
  },
  methods: {
    async teacherDetail(id) {
      let path = `/teacher-detail`;
      await this.$router.push({
        path: path,
        query: { id: id },
      });
    },
    courseTimeCalculateDisabled(enrolls) {
      if (!enrolls) {
        return true;
      } else {
        const enrollsActive = enrolls.filter((enroll) => enroll.status);
        if (enrollsActive.length <= 0) {
          return true;
        } else {
          const now = moment();
          let disabled = true;

          enrollsActive.forEach((enroll) => {
            const start_at = moment(enroll.register_start);
            const end_at = moment(enroll.register_end);
            if (now.isBetween(start_at, end_at)) {
              disabled = false;
            }
          });

          return disabled;
        }
      }
    },
    inTimeDuration(startTimeString, endTimeString) {
      const startTime = moment(startTimeString);
      const endTime = moment(endTimeString);
      const now = moment();

      return now >= startTime && now <= endTime;
    },
    checkCourseStatus() {
      const model = {
        user_id: this.userData.id,
        subject_id: this.course.id,
        accessToken: this.access_token,
      };
      this.$store
        .dispatch("Courses/checkCourseStatus", model)
        .then((response) => {
          this.courseStatus = response;
        });
    },
    countLesson() {
      const onlyLesson = this.lessons.filter((x) => x.type === "lesson");
      const onlyQuiz = this.lessons.filter((x) => x.type === "quiz");
      this.lessonsAmount = onlyLesson.length;
      this.quizAmount = onlyQuiz.length;
      this.totalTime = 0;
      onlyLesson.forEach((x) => {
        this.totalTime += x.lesson_minute;
      });
    },
    register() {
      if (!this.access_token) {
        this.$router.push("/login");
        return;
      }

      const enrollsActive = this.course.enroll.filter(
        (enroll) => enroll.status
      );

      const now = moment();

      const enroll = enrollsActive.find((enroll) => {
        const start_at = moment(enroll.register_start);
        const end_at = moment(enroll.register_end);
        return now.isBetween(start_at, end_at);
      });

      Swal.fire({
        title: "ระบบกำลังทำงาน",
        html: "โปรดรอสักครู่...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          const model = {
            user_id: this.userData.id,
            subject_id: this.course.id,
            enroll_id: enroll.id,
          };
          this.$store
            .dispatch("Courses/registerCourse", {
              data: model,
              accessToken: this.access_token,
            })
            .then(() => {
              // this.checkCourseStatus();
              this.$emit("checkCourseStatus");
              Swal.clickConfirm();
            })
            .catch((err) => {
              if (err.response.status == 401) {
                this.$store.dispatch("Auth/logout").then(() => {
                  this.$router.push("/login");
                });
              }
            });
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
