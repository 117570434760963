<template>
  <div id="register">
    <Loading :loading="isLoading"></Loading>
    <top-nav></top-nav>
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
      >
        <h3 class="text-lg-left mt-5"><b>ลงทะเบียน</b></h3>
        <hr style="border: 1px solid #0c4ca3" />
      </b-col>
    </b-row>
    <div v-if="!image" class="d-flex justify-content-center">
      <label
        class="
          border border-dark
          rounded-circle
          mr-3
          d-flex
          align-items-center
          justify-content-center
          pointer
        "
        style="width: 150px; height: 150px"
        for="uploadImage"
      >
        <b-icon class="font-title" icon="plus-circle"></b-icon>
      </label>
      <input
        @click="show = true"
        id="uploadImage"
        style="visibility: hidden; width: 0px"
      />
    </div>
    <div class="d-flex justify-content-center" v-else>
      <img
        class="mr-3 fluid rounded-circle"
        style="width: 150px; height: 150px"
        :src="`${image}`"
        alt=""
      />
      <b-icon @click="image = ''" fab icon="dash-circle" class="close"></b-icon>
    </div>
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
        class="mb-3"
      >
        <b-form-group id="field-ministry" label="กระทรวง" label-for="ministry">
          <b-form-select
            id="ministry"
            @change="onMinistryChange($event, 'getUnitMinistry')"
            value-field="ministry"
            text-field="ministry"
            v-model="ministry"
            :options="ministryOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="otherSelected"
          id="field-ministry_other"
          label="เพิ่มเติม"
          label-for="ministry_other"
        >
          <b-form-input
            id="ministry_other"
            placeholder="เพิ่มเติม"
            v-model="ministryOther"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-unitMinistry"
          label="หน่วยงานสังกัดกระทรวง"
          label-for="unitMinistry"
        >
          <b-form-select
            id="unitMinistry"
            :disabled="otherSelected"
            v-model="unitMinistry"
            @change="onMinistryChange($event, 'getUnit')"
            value-field="unit_ministry"
            text-field="unit_ministry"
            :options="unitMinistryOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group id="field-unit" label="หน่วยงานของท่าน" label-for="unit">
          <b-form-select
            id="unit"
            :disabled="otherSelected"
            v-model="unit"
            @change="onMinistryChange($event, 'getDepartment')"
            value-field="unit"
            text-field="unit"
            :options="unitOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="field-department"
          label="คณะ/สถาบัน/สำนัก/กอง"
          label-for="department"
        >
          <b-form-select
            id="department"
            :disabled="otherSelected"
            v-model="department"
            value-field="department"
            text-field="department"
            :options="departmentOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="field-title"
          label="คำนำหน้าชื่อ"
          label-for="title"
          :state="stateTitle"
        >
          <b-form-select
            id="title"
            v-model="title"
            :state="stateTitle"
            :options="titleOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="field-firstname"
          label="ชื่อ"
          label-for="firstname"
          :state="stateFirstname"
        >
          <b-form-input
            id="firstname"
            placeholder="ชื่อ"
            v-model="firstname"
            :state="stateFirstname"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-lastname"
          label="นามสกุล"
          label-for="lastname"
          :state="stateLastName"
        >
          <b-form-input
            id="lastname"
            placeholder="นามสกุล"
            v-model="lastname"
            :state="stateLastName"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-birthDate"
          label="วัน/เดือน/ปีเกิด"
          label-for="birthDate"
          :state="stateBirthDate"
        >
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                v-model="computedDateFormatted"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              @input="menu2 = false"
              locale="th-th"
            ></v-date-picker>
          </v-menu>
        </b-form-group>
        <b-form-group
          id="field-gender"
          label="เพศ"
          label-for="gender"
          :state="stateGender"
        >
          <b-form-select
            id="gender"
            :options="sexOptions"
            v-model="gender"
            :state="stateGender"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="field-email"
          label="อีเมล"
          label-for="email"
          :state="stateEmail"
        >
          <b-form-input
            id="email"
            placeholder="example@email.com"
            v-model="email"
            :state="stateEmail"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-password"
          label="รหัสผ่าน"
          label-for="password"
          :state="statePassword"
        >
          <b-form-input
            id="password"
            type="password"
            minlength="8"
            placeholder="รหัสผ่านควรประกอบด้วยตัวอักษร(a-z,A-Z)ตัวเลข(0-9) ความยาว 8 ตัวอักษร"
            v-model="password"
            :state="statePassword"
            trim
          ></b-form-input>
          <label
            ><input class="my-2" type="checkbox" @click="showPassword()" />
            แสดงรหัสผ่าน</label
          >
        </b-form-group>
        <b-form-group
          id="field-confirmpassword"
          label="ยืนยันรหัสผ่าน"
          label-for="confirmpassword"
          :state="stateConfirmPassword"
        >
          <b-form-input
            id="confirmpassword"
            type="password"
            minlength="8"
            placeholder="กรอกรหัสผ่านให้ตรงกัน"
            v-model="confirmpassword"
            :state="stateConfirmPassword"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-officePhone"
          label="เบอร์ติดต่อ(ภายใน)"
          label-for="officePhone"
          :state="stateOfficePhone"
        >
          <b-form-input
            id="officePhone"
            placeholder="เบอร์ติดต่อ(ภายใน)"
            v-model="officePhone"
            :state="stateOfficePhone"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-phone"
          label="เบอร์โทรศัพท์"
          maxlength="10"
          label-for="phone"
          :state="statePhone"
        >
          <b-form-input
            id="phone"
            placeholder="เบอร์โทรศัพท์"
            v-model="phone"
            :state="statePhone"
            trim
          ></b-form-input>
          <!-- <h5 class="text-lg-left mt-4"><b>ความเชี่ยวชาญ</b></h5>
          <hr style="border: 1px solid #0C4CA3" /> -->
        </b-form-group>
        <b-form-group
          id="field-isced1"
          label="สาขา ISCED1"
          label-for="isced1"
          :state="stateIsced1"
        >
          <!-- <b-form-select
            id="isced1"
            :options="isced1Options"
            v-model="isced1"
            :state="stateIsced1"
          ></b-form-select> -->
          <b-form-input id="isced1" v-model="isced1" trim></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-isced2"
          label="สาขา ISCED2"
          label-for="isced2"
          :state="stateIsced2"
        >
          <!-- <b-form-select
            id="isced2"
            :options="isced2Options"
            v-model="isced2"
            :state="stateIsced2"
          ></b-form-select> -->
          <b-form-input id="isced2" v-model="isced2" trim></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-isced3"
          label="สาขา ISCED3"
          label-for="isced3"
          :state="stateIsced3"
        >
          <!-- <b-form-select
            id="isced3"
            :options="isced3Options"
            v-model="isced3"
            :state="stateIsced3"
          ></b-form-select> -->
          <b-form-input id="isced3" v-model="isced3" trim></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-expert"
          label="ความเชี่ยวชาญ"
          label-for="expert"
          :state="stateExpert"
        >
          <b-form-input
            id="expert"
            placeholder="ระบุความเชี่ยวชาญ"
            :state="stateExpert"
            v-model="expert"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
        class="mb-3"
      >
        <b-button
          @click="submit()"
          id="btnSubmit"
          class="btn main-orange-btn btn-block my-5"
          >ลงทะเบียน</b-button
        >
      </b-col>
    </b-row>
    <ImageCrop
      @image="image = $event"
      @toggleShow="show = $event"
      :isShow="show"
    />
  </div>
</template>

<script>
import TopNav from "@/components/topNav";
import Swal from "sweetalert2";
import ImageCrop from "@/components/ImageCrop";
import Loading from "@/components/Loading";
import { mapState } from "vuex";
// import Google from "@/components/SocialLogin/Google";
// import Facebook from "@/components/SocialLogin/Facebook";
export default {
  components: {
    TopNav,
    ImageCrop,
    Loading,
    // Google,
    // Facebook
  },
  async created() {
    await this.$store.dispatch("Ministry/getMinistry");
  },
  computed: {
    computedDateFormatted() {
      if (!this.birthDate) return null;
      const [year, month, day] = this.birthDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    ...mapState({
      ministryOptions: (state) => {
        return state.Ministry.ministry;
      },
      unitMinistryOptions: (state) => {
        return state.Ministry.unitMinistry;
      },
      unitOptions: (state) => {
        return state.Ministry.unit;
      },
      departmentOptions: (state) => {
        return state.Ministry.department;
      },
    }),
    stateTitle() {
      return this.title != "";
    },
    stateGender() {
      return this.gender != "";
    },
    stateFirstname() {
      return this.firstname.length > 0;
    },
    stateLastName() {
      return this.lastname.length > 0;
    },
    stateBirthDate() {
      return this.birthDate != "";
    },
    statePhone() {
      return this.phone != "";
    },
    stateOfficePhone() {
      return this.officePhone != "";
    },
    stateIsced1() {
      return this.isced1 != "";
    },
    stateIsced2() {
      return this.isced2 != "";
    },
    stateIsced3() {
      return this.isced3 != "";
    },
    stateExpert() {
      return this.expert != "";
    },
    stateEmail() {
      if (this.email && this.email != "") {
        return this.email == ""
          ? ""
          : this.regEmail.test(this.email)
          ? true
          : false;
      } else {
        return false;
      }
    },
    statePassword() {
      if (this.password && this.password != "") {
        if (this.password.length >= 8) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    stateConfirmPassword() {
      if (this.password && this.password != "") {
        if (this.confirmpassword === this.password) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    invalidFeedback() {
      if (this.firstname.length >= 0) {
        return "โปรดกรอกข้อมูล";
      }
      return "โปรดกรอกข้อมูล";
    },
    invalidFeedbackPassword() {
      if (this.confirmpassword != this.password) {
        return false;
      }
      return true;
    },
    invalidFeedbackEmail() {
      let email = this.email;
      if (email) {
        let check_email = this.regEmail.test(email);
        if (!check_email) {
          return "โปรดกรอกข้อมูลให้ถูกต้อง";
        }
      }
      return "โปรดกรอกข้อมูล";
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      menu: false,
      modal: false,
      menu2: false,
      isLoading: false,
      otherSelected: false,
      show: false,
      isced1Options: [],
      isced2Options: [],
      isced3Options: [],
      titleOptions: ["นาย", "นาง", "นส.", "นพ.", "พญ."],
      sexOptions: [
        { value: "male", text: "ชาย" },
        { value: "female", text: "หญิง" },
        { value: "other", text: "อื่นๆ" },
      ],
      max: today,
      regEmail:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      firstname: "",
      lastname: "",
      citizen_id: "",
      password: "",
      confirmpassword: "",
      email: "",
      officePhone: "",
      phone: "",
      gender: "",
      address: "-",
      birthDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      title: "",
      ministry: "",
      ministryOther:"",
      unitMinistry: "",
      unit: "",
      department: "",
      isced1: "",
      isced2: "",
      isced3: "",
      image: "",
      expert: "",
    };
  },
  methods: {
    onMinistryChange(event, topic) {
      if (topic === "getUnitMinistry") {
        if (event === "อื่นๆ") {
          this.otherSelected = true;
          this.unitMinistry = "";
          this.unit = "";
          this.department = "";
        } else {
          this.otherSelected = false;
        }
      } else {
        this.otherSelected = false;
      }
      this.$store.dispatch(`Ministry/${topic}`, event);
    },
    showPassword: function () {
      var passwordInput = document.getElementById("password");
      if (passwordInput.type == "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    },
    async submit() {
      let data = {
        first_name: this.firstname,
        last_name: this.lastname,
        // citizen_id: this.citizen_id,
        email: this.email,
        password: this.password,
        birth_date: this.birthDate,
        phone: this.phone,
        office_phone: this.officePhone,
        title: this.title,
        ministry: this.ministry,
        ministryOther: this.ministryOther,
        unit_ministry: this.unitMinistry,
        department: this.department,
        gender: this.gender,
        unit: this.unit,
        isced_one: this.isced1,
        isced_two: this.isced2,
        isced_three: this.isced3,
        expert: this.expert,
      };

      let validateResult = this.validateForm(data);
      if (validateResult) {
        await this.goRegister(data);
      }
    },
    validateForm(data) {
      let check_email = this.regEmail.test(data.email);
      //for (var index in data) {
      //var attr = data[index];
      // if (!attr && attr == "") {
      //   Swal.fire("ผิดพลาด!", "โปรดกรอกข้อมูลให้ครบ", "error");
      //   return false;
      // } else
      if (!check_email) {
        Swal.fire("ผิดพลาด!", "โปรดกรอกอีเมลให้ถูกต้อง", "error");
        return false;
      } else if (this.confirmpassword != data.password) {
        Swal.fire("ผิดพลาด!", "โปรดกรอกรหัสให้ตรงกัน", "error");
        return false;
      } else if (!data.birth_date || data.birth_date == "") {
        Swal.fire("ผิดพลาด!", "โปรดกรอก วัน/เดือน/ปีเกิด", "error");
        return false;
      }
      //}
      return true;
    },
    async goRegister(data) {
      const path = `/login`;
      let formData = new FormData();
      formData.append("data", JSON.stringify(data));
      formData.append("image", this.image);
      (this.isLoading = true),
        await this.$store
          .dispatch("Auth/registerWC", formData)
          .then((response) => {
            if (response.code == 200) {
              Swal.fire({
                title: "สำเร็จ!",
                text: "ลงทะเบียนเรียบร้อย",
                confirmButtonText: `ตกลง`,
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push(path);
                }
              });
            }
          });
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss">
#register {
  height: 100%;
  min-height: 100vh;
}
</style>
