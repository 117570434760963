<template>
  <div class="container mx-3">
    <Loading :loading="isLoading"></Loading>
    <div class="mx-auto d-flex mt-3 justify-content-center">
      <!-- <img class="fluid" src="../assets/image/mock/user_profile.png" alt="" />
      <div class="mt-2">แก้ไขรูปโปรไฟล์</div> -->
      <div v-if="!imageProfile">
        <label
          class="
            border border-dark
            rounded-circle
            mr-3
            d-flex
            align-items-center
            justify-content-center
            pointer
          "
          style="width: 150px; height: 150px"
          for="uploadImage"
        >
          <b-icon class="font-title" icon="plus-circle"></b-icon>
        </label>
        <input
          @click="show = true"
          id="uploadImage"
          style="visibility: hidden; width: 0px"
        />
      </div>
      <div v-else>
        <img
          class="mr-3 fluid rounded-circle"
          style="width: 150px; height: 150px"
          :src="`${imageProfile}`"
          alt=""
        />
        <b-icon
          fab
          icon="dash-circle"
          class="close"
          @click="
            imageProfile = '';
            hasImage = 0;
          "
        ></b-icon>
      </div>
      <label for=""></label>
    </div>
    <div class="row mt-5">
      <div class="col-md-6 col-12">
        <div class="row align-items-center">
          <div class="col-4 mt-2">
            <label>คำนำหน้าชื่อ</label>
            <b-form-select
              v-model="userModel.title"
              :options="titleOptions"
            ></b-form-select>
          </div>
          <div class="col-4 mt-2">
            <label>ชื่อ</label>
            <input
              disabled
              class="form-control"
              v-model="userModel.first_name"
              type="text"
            />
          </div>
          <div class="col-4 mt-2">
            <label>นามสกุล</label>
            <input
              disabled
              class="form-control"
              v-model="userModel.last_name"
              type="text"
            />
          </div>
          <div class="col-6 mt-2">
            <label>เลขบัตรประจำตัวประชาชน</label>
            <input
              class="form-control"
              v-model="userModel.citizen_id"
              type="text"
            />
          </div>
          <div class="col-6 mt-2">
            <label>Email</label>
            <input class="form-control" v-model="userModel.email" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>เบอร์โทรศัพท์มือถือ</label>
            <input class="form-control" v-model="userModel.phone" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>เบอร์โทรศัพท์สำนักงาน</label>
            <input
              class="form-control"
              v-model="userModel.office_phone"
              type="text"
            />
          </div>
          <div class="col-12 mt-2">
            <label>ที่อยู่</label>
            <textarea
              class="form-control"
              v-model="userModel.address"
              rows="2"
            ></textarea>
          </div>
          <div class="col-12 mt-2">
            <label>วันเกิด</label>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-model="computedDateFormatted"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="userModel.birth_date"
                @input="menu2 = false"
                locale="th-th"
              ></v-date-picker>
            </v-menu>
            <!-- <input
              class="form-control"
              v-model="userModel.birth_date"
              type="date"
            /> -->
          </div>
          <div class="col-12 mt-2">
            <label>เพศ</label>
            <b-form-radio-group
              v-model="userModel.gender"
              :options="genderOptions"
              class="mb-3"
              value-field="value"
              text-field="name"
            ></b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-6 mt-2">
            <label>กระทรวง</label>
            <b-form-select
              id="ministry"
              @change="onMinistryChange($event, 'getUnitMinistry')"
              value-field="ministry"
              text-field="ministry"
              v-model="userModel.ministry"
              :options="ministryOptions"
            ></b-form-select>
            <!-- <input
              v-model="userModel.government"
              class="form-control"
              type="text"
            /> -->
          </div>
          <div v-if="otherSelected" class="col-6 mt-2">
            <label>เพิ่มเติม</label>
            <input
              v-model="userModel.ministryOther"
              class="form-control"
              type="text"
            />
            <!-- <input
              v-model="userModel.government"
              class="form-control"
              type="text"
            /> -->
          </div>
          <div class="col-6 mt-2">
            <label>หน่วยงานสังกัดกระทรวง</label>
            <b-form-select
              id="unitMinistry"
              :disabled="otherSelected"
              v-model="userModel.unit_ministry"
              @change="onMinistryChange($event, 'getUnit')"
              value-field="unit_ministry"
              text-field="unit_ministry"
              :options="unitMinistryOptions"
            ></b-form-select>
            <!-- <input
              v-model="userModel.agency_government"
              class="form-control"
              type="text"
            /> -->
          </div>
          <div class="col-6 mt-2">
            <label>หน่วยงานของท่าน</label>
            <b-form-select
              id="unit"
              :disabled="otherSelected"
              v-model="userModel.unit"
              @change="onMinistryChange($event, 'getDepartment')"
              value-field="unit"
              text-field="unit"
              :options="unitOptions"
            ></b-form-select>
            <!-- <input
              v-model="userModel.agency"
              class="form-control"
              type="text"
            /> -->
          </div>
          <div class="col-6 mt-2">
            <label>คณะ/สถาบัน/สำนัก/กอง</label>
            <b-form-select
              id="department"
              :disabled="otherSelected"
              v-model="userModel.department"
              value-field="department"
              text-field="department"
              :options="departmentOptions"
            ></b-form-select>
            <!-- <input v-model="userModel.unit" class="form-control" type="text" /> -->
          </div>
          <div class="col-12 mt-2">
            <label class="font-weight-bold">ความเชี่ยวชาญ</label>
          </div>
          <div class="col-12 mt-2">
            <label>สาขา ISCED1</label>
            <input
              v-model="userModel.isced_one"
              class="form-control"
              type="text"
            />
          </div>
          <div class="col-12 mt-2">
            <label>สาขา ISCED2</label>
            <input
              v-model="userModel.isced_two"
              class="form-control"
              type="text"
            />
          </div>
          <div class="col-12 mt-2">
            <label>สาขา ISCED3</label>
            <input
              v-model="userModel.isced_three"
              class="form-control"
              type="text"
            />
          </div>
          <div class="col-12 mt-2">
            <label>ความเชี่ยวชาญ</label>
            <input
              v-model="userModel.expert"
              class="form-control"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mt-5">
      <div class="col-md-3 col-6">
        <button
          @click="$emit('modalClosed')"
          class="btn btn-block main-orange-btn"
        >
          ยกเลิก
        </button>
      </div>
      <div class="col-md-3 col-6">
        <button @click="onSave()" class="btn btn-block main-orange-btn-outline">
          บันทึก
        </button>
      </div>
    </div>
    <ImageCrop
      @image="
        imageProfile = $event;
        hasImage = 1;
      "
      @toggleShow="show = $event"
      :isShow="show"
    />
  </div>
</template>

<script>
import { imageDomain } from "../util/Constants";
import Swal from "sweetalert2";
import ImageCrop from "@/components/ImageCrop";
import Loading from "@/components/Loading";
import { mapState } from "vuex";
export default {
  props: ["propData"],
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("Ministry/getMinistry");
    await this.$store
      .dispatch("Auth/getUserNRCT", this.propData.id)
      .then(async (response) => {
        if (response.code == 200) {
          this.userModel = {
            id: this.propData.id,
            first_name: this.propData.first_name,
            title: this.propData.title,
            last_name: this.propData.last_name,
            email: this.propData.email,
            phone: this.propData.phone,
            office_phone: this.propData.office_phone,
            citizen_id: this.propData.citizen_id,
            birth_date: this.propData.birth_date,
            gender: this.propData.gender,
            address: this.propData.address,
            profile_image: this.propData.profile_image,
            department: response.result ? response.result.department : "",
            unit_ministry: response.result ? response.result.unit_ministry : "",
            expert: response.result ? response.result.expert : "",
            ministry: response.result ? response.result.ministry : "",
            isced_one: response.result ? response.result.isced_one : "",
            isced_three: response.result ? response.result.isced_three : "",
            isced_two: response.result ? response.result.isced_two : "",
            unit: response.result ? response.result.unit : "",
            user_nrct_id: response.result ? response.result.id : "",
          };
          if (response.result.ministry) {
            this.$store
              .dispatch("Ministry/getUnitMinistry", response.result.ministry)
              .then(() => {
                this.userModel.unit_ministry = response.result.unit_ministry;
              });
          }
          if (response.result.unit_ministry) {
            this.$store
              .dispatch("Ministry/getUnit", response.result.unit_ministry)
              .then(() => {
                this.userModel.unit = response.result.unit;
              });
          }
          if (response.result.unit) {
            this.$store
              .dispatch("Ministry/getDepartment", response.result.unit)
              .then(() => {
                this.userModel.department = response.result.department;
              });
          }
        }
      });

    this.imageProfile = this.propData.profile_image;
    this.hasImage = this.propData.profile_image ? 1 : 0;
    this.isLoading = false;
  },
  computed: {
    computedDateFormatted() {
      if (!this.userModel.birth_date) return null;
      const [year, month, day] = this.userModel.birth_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    ...mapState({
      ministryOptions: (state) => {
        return state.Ministry.ministry;
      },
      unitMinistryOptions: (state) => {
        return state.Ministry.unitMinistry;
      },
      unitOptions: (state) => {
        return state.Ministry.unit;
      },
      departmentOptions: (state) => {
        return state.Ministry.department;
      },
    }),
  },
  components: {
    ImageCrop,
    Loading,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      isLoading: false,
      titleOptions: ["นาย", "นาง", "นส.", "นพ.", "พญ."],
      show: false,
      hasImage: "",
      imageDomain: imageDomain,
      tempImage: null,
      otherSelected: false,
      userModel: {
        id: "",
        first_name: "",
        title: "",
        last_name: "",
        email: "",
        phone: "",
        office_phone: "",
        citizen_id: "",
        birth_date: "",
        gender: "",
        address: "",
        profile_image: "",
        department: "",
        unit_ministry: "",
        expert: "",
        ministry: "",
        ministryOther:"",
        isced_one: "",
        isced_three: "",
        isced_two: "",
        unit: "",
        user_nrct_id: "",
      },
      imageProfile: null,
      genderOptions: [
        { value: "male", name: "ชาย" },
        { value: "female", name: "หญิง" },
        { value: "other", name: "ไม่ระบุ" },
      ],
    };
  },
  methods: {
    onMinistryChange(event, topic) {
      if (topic === "getUnitMinistry") {
        if (event === "อื่นๆ") {
          this.otherSelected = true;
          this.userModel.unit_ministry = "";
          this.userModel.unit = "";
          this.userModel.department = "";
        } else {
          this.otherSelected = false;
        }
      } else {
        this.otherSelected = false;
      }
      this.$store.dispatch(`Ministry/${topic}`, event);
    },
    async onSave() {
      if (!this.imageProfile) {
        this.userModel.has_profile_image = false;
      }
      let formData = new FormData();
      this.userModel.hasImage = this.hasImage;
      formData.append("data", JSON.stringify(this.userModel));
      formData.append("image", this.imageProfile);
      (this.isLoading = true),
        await this.$store
          .dispatch("Auth/editUserProfile", formData)
          .then((response) => {
            if (response.code == 200) {
              this.$store.dispatch("Auth/getUserNRCT", response.result.id);
              this.$emit("modalClosed");
            }
          })
          .catch((e) => {
            console.log("response", e);
            Swal.fire("ผิดพลาด!", "ไม่สามารถแก้ไขข้อมูลได้", "error");
          });
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
