<template>
  <div class="fill-height">
    <Loading :loading="isLoading"></Loading>
    <TopNav />
    <div class="container pt-5 fill-height lighter-grey-bg">
      <div class="px-md-5">
        <h1>{{ question.name }}</h1>

        <div class="horizon-line my-5"></div>
        <div>เหลือเวลา {{ timeLeft }}</div>
        <div v-for="(item, index) in question.questions" :key="index">
          <span v-if="item.answer_type === 'radio'">
            <RadioQuiz
              @clickAnswer="onClickAnswer"
              :question="item"
              :user-answer="userAnswer"
              :random-choice="question.random_choice"
              class="mt-4"
            ></RadioQuiz>
          </span>
          <span v-if="item.answer_type === 'checkbox'">
            <CheckBoxQuiz
              @clickAnswer="onClickAnswer"
              :question="item"
              class="mt-4"
            ></CheckBoxQuiz>
          </span>
          <span v-if="item.answer_type === 'text'">
            <TextQuiz
              @answer="onClickAnswer"
              :question="item"
              :user-answer="userAnswer"
              class="mt-4"
            ></TextQuiz>
          </span>
        </div>
        <div class="row justify-content-end py-4">
          <div class="col-6 col-md-3">
            <button
              @click="sendAnswer()"
              class="btn btn-block main-orange-btn-outline"
            >
              บันทึกคำตอบ
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      centered
      id="show-answer-modal"
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
    >
      <ShowAnswer :question="allQuestion" />
    </b-modal>
  </div>
</template>

<script>
import ShowAnswer from "../views/ShowAnswer.vue";
import Swal from "sweetalert2";
import TopNav from "@/components/topNav.vue";
import CheckBoxQuiz from "@/components/Quiz/CheckBoxQuiz.vue";
import RadioQuiz from "@/components/Quiz/RadioQuiz.vue";
import TextQuiz from "@/components/Quiz/TextQuiz.vue";
import mockJson from "@/assets/question_set.json";
import Loading from "@/components/Loading.vue";
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      allAnswer: [],
      mockJson: mockJson,
      isLoading: false,
      timeLeft: "0:00 นาที",
      timeInterval: null,
      notice: null,
      isSend: false,
      allQuestion: [],
      lodash: _,
    };
  },
  computed: {
    ...mapState({
      access_token: (state) => state.Auth.access_token,
      question: (state) => {
        if (state.Question.question.random_item) {
          return {
            ...state.Question.question,
            questions: _.shuffle(state.Question.question.questions),
          };
        }
        return state.Question.question;
      },
      userAnswer: (state) => {
        return state.Question.answer;
      },
      userUnfinishAnswer: (state) => {
        return state.Question.unfinishAnswer;
      },
      userData: (state) => state.Auth.userData,
    }),
  },
  watch: {
    $route() {
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    // await this.getContent();
    await this.getNotice();

    this.isLoading = false;
  },
  methods: {
    async getNotice() {
      const quizId = this.$route.query.id;
      const questionSet = await this.$store.dispatch(
        "Question/getQuestionNotice",
        {
          quizId,
          accessToken: this.access_token,
        }
      );

      if (!questionSet.result.question) {
        this.$router.back();
        return;
      }

      this.notice = questionSet.result.question.notice_message;

      if (this.notice) {
        const isConfirmed = await Swal.fire({
          title: "คำเตือน",
          // html: true,
          text: this.notice,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
        });

        if (isConfirmed.isConfirmed) {
          await this.getContent();
        }
      } else {
        await this.getContent();
      }
    },
    async getContent() {
      const quizId = this.$route.query.id;
      if (quizId) {
        await this.$store
          .dispatch("Question/getQuestion", {
            quizId,
            accessToken: this.access_token,
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.$store.dispatch("Auth/logout").then(() => {
                this.$router.push("/login");
              });
            }
          });

        if (this.userUnfinishAnswer) {
          if (this.question.time_limit_minute) {
            this.timeInterval = setInterval(async () => {
              if (this.$route.name != "quiz") {
                clearInterval(this.timeInterval);
                return;
              }
              const now = moment();
              const timeCreate = moment(this.userUnfinishAnswer.created_at);
              const timeMustFinish = timeCreate
                .clone()
                .add(this.question.time_limit_minute, "minutes");

              if (timeMustFinish.diff(now) <= 0) {
                clearInterval(this.timeInterval);
                await this.forceEndQuiz(true);
              }

              const duration = moment
                .duration(timeMustFinish.diff(now))
                .asSeconds();
              this.timeLeft = `${parseInt(duration / 60)}:${parseInt(
                duration % 60
              )} นาที`;
            }, 1000);
          }
        }

        if (!this.userUnfinishAnswer) {
          await this.forceEndQuiz();
        }
      } else {
        this.$router.push("/");
      }
    },
    async forceEndQuiz(sendAnswer = false) {
      if (sendAnswer) {
        const model = {
          answer_json: this.allAnswer,
          user_id: this.userData.id,
          subject_id: this.question.subject_id,
          lession_id: this.question.lesson_id,
          question_set_id: this.question.id,
          max_score: this.question.full_score,
          enroll_id: this.$route.query.enrollId,
        };
        await this.sendAnswerHandler(model);
      }

      if (this.isSend) {
        await Swal.fire({
          title: "คำเตือน",
          text: "หมดเวลาทำข้อสอบแล้ว",
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
        });
        this.$router.back();
      }
    },
    async sendAnswerHandler(model) {
      if (this.isSend) {
        return;
      }
      this.isSend = true;
      this.isLoading = true;
      await this.$store
        .dispatch("Question/sendAnswer", {
          data: model,
          accessToken: this.access_token,
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.isLoading = false;
          // มีเฉลยไหม
          if (this.question.show_answer) {
            if (this.question.max_show_question > 0) {
              this.allQuestion = this.limitQuestion(
                this.question.questions,
                response.result
              );
            } else {
              this.allQuestion = response.result;
            }

            this.$bvModal.show("show-answer-modal");
          } else {
            this.$router.back();
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store.dispatch("Auth/logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
    },
    limitQuestion(questions, allQuestion) {
      let showQuestion = [];
      allQuestion.forEach((all) => {
        questions.forEach((limit) => {
          if (all.id === limit.id) {
            showQuestion.push(all);
          }
        });
      });
      return showQuestion;
    },
    async sendAnswer() {
      let questionAmount = this.question.questions.length;
      let answerAmount = this.allAnswer.length;
      //this.question.show_answer
      if (questionAmount != answerAmount) {
        Swal.fire("ผิดพลาด!", "กรุณาตอบคำถามให้ครบทุกข้อ", "error");
      } else {
        const model = {
          answer_json: this.allAnswer,
          user_id: this.userData.id,
          subject_id: this.question.subject_id,
          lession_id: this.question.lesson_id,
          question_set_id: this.question.id,
          max_score: this.question.full_score,
          enroll_id: this.$route.query.enrollId,
        };
        Swal.fire({
          title: "ต้องการส่งคำตอบ ?",
          showCancelButton: true,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
        }).then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await this.sendAnswerHandler(model);
          }
        });
      }
    },
    onClickAnswer(model) {
      const index = this.allAnswer.findIndex(
        (x) => x.question_id === model.question_id
      );
      if (index >= 0) this.allAnswer[index] = model;
      else this.allAnswer.push(model);
    },
  },
  components: {
    TopNav,
    CheckBoxQuiz,
    ShowAnswer,
    RadioQuiz,
    TextQuiz,
    Loading,
  },
};
</script>

<style></style>
