<template>
  <div id="ForgetPasswordPage">
    <Loading :loading="isLoading"></Loading>
    <top-nav></top-nav>
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
      >
        <h3 class="text-lg-left mt-5"><b>ลืมรหัสผ่าน</b></h3>
        <hr style="border: 1px solid #0c4ca3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
      >
        <b-form-group
          id="field-email"
          label="อีเมล"
          label-for="emailLogin"
          :state="stateEmail"
        >
          <b-form-input
            id="emailLogin"
            type="email"
            placeholder="example@email.com"
            v-model="emailLogin"
            :state="stateEmail"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="4"
        offset-lg="4"
        md="4"
        offset-md="4"
        sm="12"
        cols="8"
        offset="2"
      >
        <b-button
          @click="sendEmailForgetPassword"
          id="btnLogin"
          class="btn btnHover btn-outline-light btn-block mt-3"
          style="color: black; background-color: white"
        >
          ส่งคำขอ
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import topNav from "@/components/topNav";
import Swal from "sweetalert2";

export default {
  name: "loginPage",
  data() {
    return {
      passwordLogin: "",
      emailLogin: "",
      isLoading: false,
    };
  },
  props: {
    msg: String,
  },
  components: { topNav },
  computed: {
    stateEmail() {
      if (this.emailLogin.length != 0) {
        return true;
      } else {
        return false;
      }
    },
    invalidFeedbackEmail() {
      if (this.emailLogin.length >= 0) {
        return "โปรดกรอกข้อมูล";
      }
      return "โปรดกรอกข้อมูล";
    },
  },
  methods: {
    sendEmailForgetPassword() {
      const path = `/`;
      let data = {
        email: this.emailLogin,
      };
      this.isLoading = true;
      this.$store
        .dispatch("Auth/forgotPassword", data)
        .then((response) => {
          this.isLoading = false;
          if (response.code == 200) {
            console.log("response", response);
            Swal.fire({
              title: "สำเร็จ!",
              confirmButtonText: `ตกลง`,
              icon: "success",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push(path);
              }
            });
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log("response", e);
          Swal.fire(
            "ผิดพลาด!",
            "ไม่สามารถเข้าสู่ระบบได้ โปรดตรวจสอบอีเมล/รหัสผ่าน",
            "error"
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#ForgetPasswordPage {
  height: 100%;
  min-height: 100vh;
}
* {
  box-sizing: border-box;
}

.vue-tempalte {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  justify-content: center;
  form {
    min-width: 40%;
  }
}

.vertical-center {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.vertical-center {
  display: flex;
  text-align: left;
  justify-content: center;
  flex-direction: column;
}

.inner-block {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.vertical-center .form-control:focus {
  border-color: #2554ff;
  box-shadow: none;
}

.vertical-center h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label {
  font-weight: 500;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7a7a7a;
  margin: 0;
}

.forgot-password a {
  color: #2554ff;
}

.social-icons {
  text-align: center;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.5em;
  color: #222222;
}

.social-icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.social-icons ul li {
  display: inline-block;
  zoom: 1;
  width: 65px;
  vertical-align: middle;
  border: 1px solid #e3e8f9;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  margin-right: 5px;
  background: #f4f6ff;
}

.social-icons ul li a {
  display: block;
  font-size: 1.4em;
  margin: 0 5px;
  text-decoration: none;
}

.social-icons ul li a i {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.social-icons ul li a:focus i,
.social-icons ul li a:active i {
  transition: none;
  color: #222222;
}
</style>
