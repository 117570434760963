<template>
  <div class="fill-height">
    <Loading :loading="isLoading"></Loading>
    <topNav />
    <div class="container mt-5">
      <h1>{{ lesson.name }}</h1>
      <b-row class="my-2 font-weight-bold" v-if="lesson.teachers.length > 0">
        <b-col>
          <span>ผู้สอน </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="teacher in lesson.teachers"
          :key="teacher.id"
          md="4"
          cols="12"
          class="mb-4"
        >
          <b-card
            @click="teacherDetail(teacher.user.id)"
            class="mx-auto my-auto text-center"
          >
            <template v-if="teacher.user">
              <b-avatar :src="teacher.user.profile_image"></b-avatar>
              <span>
                {{ teacher.user.first_name }} {{ teacher.user.last_name }}
              </span>
            </template>
          </b-card>
        </b-col>
        <b-col md="3" cols="12" class="mb-4" v-if="lesson.pdf">
          <button
            @click="downloadFile(lesson.pdf.url)"
            class="btn main-orange-btn btn-block"
          >
            Download เอกสาร
          </button>
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col md="3" cols="12" class="mb-4">
          <button class="btn main-orange-btn btn-block">
            ถามตอบ เรื่องบทเรียน
          </button>
        </b-col> -->
      </b-row>

      <b-row class="text-center">
        <b-col
          v-if="
            lesson.media ||
            lesson.video_lesson_id_360 > 0 ||
            lesson.video_lesson_id_480 > 0 ||
            lesson.video_lesson_id_720 > 0 ||
            lesson.video_lesson_id_1080 > 0
          "
          cols="12"
          class="my-3"
        >
          <img
            style="max-width: 20%"
            class="absolute-img"
            src="../../public/logo_water.png"
          />
          <vue-plyr ref="player" v-if="isGetContent">
            <video
              :autoplay="$route.query.sec"
              controls
              playsinline
              :id="lesson.id"
            >
              <source
                v-if="
                  isGetContent &&
                  lesson.media &&
                  lesson.video_lesson_is_multi == 0
                "
                size="576"
                :src="lesson.media.url"
                type="video/mp4"
              />

              <!--              multi-->
              <source
                v-if="
                  isGetContent &&
                  lesson.video_lesson_is_multi == 1 &&
                  lesson.video_lesson_id_360 > 0
                "
                size="360"
                :src="lesson.video_lesson_360"
                type="video/mp4"
              />

              <source
                v-if="
                  isGetContent &&
                  lesson.video_lesson_is_multi == 1 &&
                  lesson.video_lesson_id_480 > 0
                "
                size="480"
                :src="lesson.video_lesson_480"
                type="video/mp4"
              />

              <source
                v-if="
                  isGetContent &&
                  lesson.video_lesson_is_multi == 1 &&
                  lesson.video_lesson_id_720 > 0
                "
                size="720"
                :src="lesson.video_lesson_720"
                type="video/mp4"
              />

              <source
                v-if="
                  isGetContent &&
                  lesson.video_lesson_is_multi == 1 &&
                  lesson.video_lesson_id_1080 > 0
                "
                size="1080"
                :src="lesson.video_lesson_1080"
                type="video/mp4"
              />
            </video>
          </vue-plyr>
        </b-col>
      </b-row>

      <b-row v-if="lesson.pdf" class="my-2">
        <b-col lg="12" md="12" sm="12" cols="12">
          <PDFView ref="pdfView" :src="lesson.pdf.url" :page="page"></PDFView>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12" cols="12">
          <div>
            <b-tabs content-class="mt-5" align="left">
              <b-tab title="บทเรียน" active>
                <p v-html="lesson.description"></p>
                <b>เนื้อหาในคอร์สเรียน</b>
                <LessonList
                  :selected="lesson"
                  :lessons="course ? course.lessons : []"
                  :answerCourse="answerCourse"
                  :courseStatus="courseStatus"
                  :lessonProgress="progressResponse"
                />
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LessonList from "@/components/Lessons/LessonList";
import img from "@/assets/image/mock/overview.png";
import topNav from "@/components/topNav.vue";
import Loading from "@/components/Loading.vue";
import PDFView from "./PDFView.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      video_about: null,
      poster_video: img,
      isLoading: false,
      courseStatus: false,
      answerCourse: null,
      isGetContent: false,
      syncSlide: [],
      page: 1,
      playTime: 0,
      playTimeInterval: null,
      timer: null,
      progressResponse: null,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.Auth.userData,
      access_token: (state) => state.Auth.access_token,
      course: (state) => {
        return state.Courses.course;
      },
      lesson: (state) => {
        return state.Lessons.lessons;
      },
    }),
  },
  created() {
    this.isGetContent = false;
    this.changeFormatSyncSlide();

    console.log(this.lesson, "pdf");
  },
  async mounted() {
    console.log(this.lesson);
    this.isLoading = true;

    await this.getContent();
    await this.checkCourseStatus();

    this.isLoading = false;

    if (this.syncSlide.length > 0) {
      this.$refs.player.player.on("timeupdate", (model) => {
        this.syncSlideProcess(model.detail.plyr.currentTime);
      });
    }
    this.$refs.player.player.on("loadeddata", () => {
      this.$refs.player.player.currentTime = parseInt(this.$route.query.sec);
    });

    let self = this;
    this.$refs.player.player.on("play", () => {
      console.log("play");
      this.timer = setInterval(() => {
        const currentTime = this.$refs.player.player.currentTime;
        const duration = this.$refs.player.player.duration;
        self.updateProgress(currentTime, duration);
      }, 10000);

      this.playTimeInterval = setInterval(() => {
        this.playTime++;
      }, 1);
    });

    this.$refs.player.player.on("pause", () => {
      clearInterval(this.timer);
      clearInterval(this.playTimeInterval);
    });
    this.$forceUpdate();
  },
  components: {
    LessonList,
    PDFView,
    Loading,
    topNav,
  },
  methods: {
    isShowPlayer() {
      return !this.isLoading;
    },
    async updateProgress(currentTime, duration) {
      console.log("updateProgress", currentTime, duration);

      const model = {
        user_id: this.userData.id,
        subject_id: this.lesson.subject_id,
        lesson_id: this.lesson.id,
        lesson_time_sec: currentTime,
        duration: duration,
        enroll_id: this.courseStatus.enroll_id,
        play_time: this.playTime,
      };
      this.$store
        .dispatch("Lessons/saveLessonProgress", {
          model,
          accessToken: this.access_token,
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store.dispatch("Auth/logout").then(() => {
              this.$router.push("/login");
            });
          }
        });
    },
    // Change Page when Video play to the page time
    async syncSlideProcess(currentTime) {
      // console.log(currentTime)
      this.syncSlide.forEach((model, index) => {
        if (index + 1 < this.syncSlide.length) {
          if (
            currentTime >= this.syncSlide[index].total_second &&
            currentTime <= this.syncSlide[index + 1].total_second
          ) {
            if (this.page != model.page) {
              this.page = model.page;
            }
            return;
          }
        } else {
          if (currentTime >= this.syncSlide[index].total_second) {
            if (this.page != model.page) {
              this.page = model.page;
            }
            return;
          }
        }
      });
    },

    // Prepare
    changeFormatSyncSlide() {
      if (this.lesson.sync_slide) {
        // console.log(this.lesson.sync_slide);

        this.syncSlide = JSON.parse(this.lesson.sync_slide);
        this.syncSlide = this.syncSlide.map((x) => {
          let page = parseInt(x.page);
          let total_second = parseInt(x.total_second);
          return { page: page, total_second: total_second };
        });
        this.syncSlide.sort(function (a, b) {
          return a.total_second - b.total_second;
        });
      }
    },

    async teacherDetail(id) {
      let path = `/teacher-detail`;
      await this.$router.push({
        path: path,
        query: { id: id },
      });
    },
    async checkCourseStatus() {
      const model = {
        user_id: this.userData.id,
        subject_id: this.course.id,
      };

      let courseResponse;
      try {
        courseResponse = await this.$store.dispatch(
          "Courses/checkCourseStatus",
          {
            model,
            accessToken: this.access_token,
          }
        );

        this.courseStatus = courseResponse;
        if (this.courseStatus) {
          if (this.courseStatus.isBan) {
            let path = `/courses`;
            await this.$router.push({
              path: path,
              query: { id: this.$route.query.courseId },
            });
          }
        }
      } catch (err) {
        this.isLoading = false;
        this.courseStatus = null;
      }

      let answerResponse;
      try {
        answerResponse = await this.$store.dispatch("Courses/getCourseAnswer", {
          id: this.course.id,
          accessToken: this.access_token,
          enrollId: this.courseStatus.enroll_id,
        });

        this.answerCourse = answerResponse;
      } catch (err) {
        this.isLoading = false;
        this.answerCourse = false;
      }

      await this.getLessonProgress();
    },
    downloadFile(link) {
      window.open(link, "_blank");
    },
    async getContent() {
      const course = this.$route.query.courseId;
      const lesson = this.$route.query.lessonId;
      if (course || lesson) {
        await this.$store.dispatch("Courses/getCourse", {
          id: course,
          user_id: this.userData.id,
        });
        await this.$store.dispatch("Lessons/getLessons", lesson);

        this.$forceUpdate();
        this.isGetContent = true;
      } else {
        this.$router.push("/");
      }
    },
    async getLessonProgress() {
      let progressResponse;
      try {
        progressResponse = await this.$store.dispatch(
          "Courses/onGetLessonProgess",
          {
            subjectId: this.course.id,
            enrollId: this.courseStatus.enroll_id,
            accessToken: this.access_token,
          }
        );

        this.progressResponse = progressResponse;
      } catch (error) {
        this.progressResponse = false;
      }
    },
  },
};
</script>

<style>
.absolute-img {
  position: absolute;
  z-index: 500;
  width: 120px;
  top: 4%;
  right: 4%;
}
</style>
